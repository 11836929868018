.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.untoggled-button-active {
  padding: 10px;
  margin: 10px;
  /* margin-top: 0px; */
  border-radius: 3px;
  border-style: none;
  box-shadow: inset 0 3px 10px rgb(0 0 0 / 0.4);
  background-color:  lightgreen;
  width: 80%
}

.untoggled-button {
  padding: 10px;
  margin: 10px;
  border-style: none;
  border-radius: 3px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 1);
  background-color:  lightgray;
  width: 80%
}

a:link {
  color: white;
  background-color: transparent;
  /* text-decoration: none; */
}